.visible-active {
  display: none;
}

.hidden-active {
  display: block;
}

.hidden-active-flex {
}

.track-inner {
  @apply grid gap-4 w-full text-th-foreground-max;
}

.track-row {
  border: 0.1rem solid transparent;

  &.bordered {
    &:not(.hide-border-first-row) {
      border-top-color: var(--foreground-min);
    }
  }

  &.is-dragging-over {
    &.is-dragging-over-top {
      border-top-color: var(--brand-color) !important;
    }
    &.is-dragging-over-bottom {
      border-bottom-color: var(--brand-color) !important;
    }
  }

  &.active,
  &.is-dragging {
    .track-inner {
      @apply bg-th-foreground-mid text-th-primary-foreground;

      p {
        @apply text-th-primary-foreground;
      }

      .visible-active {
        display: block;
      }

      .hidden-active, .hidden-active-flex {
        display: none;
      }
    }
  }

  &.now-playing-selected {
    .track-inner {
      @apply bg-th-foreground-low text-th-primary-foreground;

      p {
        @apply text-th-primary-foreground;
      }

      .visible-active {
        display: block;
      }

      .hidden-active, .hidden-active-flex {
        display: none;
      }

      .pause-holder {
        display: none;
      }
    }
  }

  &.is-context-menu-open {
    .track-inner {
      @apply bg-th-foreground-low text-th-primary-foreground;

      p {
        @apply text-th-primary-foreground;
      }

      .visible-active {
        display: block;
      }

      .hidden-active, .hidden-active-flex {
        display: none;
      }

      .playing-holder {
        display: none;
      }

      .pause-holder {
        display: block;
      }
    }
  }

  &.hidden-playlist-item {
    .track-inner {
      @apply opacity-50 pointer-events-none;
    }
  }

  &.track-item {
    &:not(.active) {
      .track-inner {
        &:hover,
        &:focus,
        &:active {
          @apply bg-th-foreground-low;
        }
      }
    }

    .track-inner {
      &:hover,
      &:focus,
      &:active {
        p {
          @apply text-th-primary-foreground;
        }

        .visible-active {
          display: block;
        }

        .hidden-active, .hidden-active-flex {
          display: none;
        }

        .playing-holder {
          display: none;
        }

        .pause-holder {
          display: block;
        }
      }
    }
  }

  &.is-link {
    .track-inner {
      cursor: pointer;
      @apply sm:bg-th-foreground-min-solid shadow-2xl;

      a {
        pointer-events: none;
      }
    }
  }

  &.compact {
    .track-inner {
      @apply bg-th-foreground-low-solid pt-0 pl-0 pb-0;

      .cover-wrapper {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }
  }

  &.sticky-header-track-list {
    border-top-color: var(--foreground-min);

    &.pinned {
      margin: 0 -1.5rem;
      border-bottom-color: var(--foreground-low-solid);

      @apply bg-th-primary-background px-6 z-10;
    }
  }
}
