.rc-slider {
  @apply rounded-sm p-0;
  height: auto;
  padding: 0;

  &.rc-slider-disabled {
    @apply bg-transparent;
  }

  &.xs {
    .rc-slider-rail {
      height: 0.1rem;
    }

    .rc-slider-track {
      height: 0.1rem;
    }
  }

  &.sm {
    .rc-slider-rail {
      height: 0.25rem;
    }

    .rc-slider-track {
      height: 0.25rem;
    }
  }

  &.md {
    .rc-slider-rail {
      height: 0.3rem;
    }

    .rc-slider-track {
      height: 0.3rem;
    }
  }

  &.lg {
    .rc-slider-rail {
      height: 0.4rem;
    }

    .rc-slider-track {
      height: 0.4rem;
    }
  }

  .rc-slider-rail {
    @apply bg-th-foreground-min rounded-sm;
  }

  &.transparent-rail {
    .rc-slider-rail {
      @apply bg-transparent;
    }
  }

  .rc-slider-track {
    @apply bg-th-foreground-high;
  }

  &.rj-slider {
    .rc-slider-track {
      @apply bg-th-brand-color;
    }
  }

  &.smooth {
    .rc-slider-track,
    .rc-slider-handle {
      @apply transition-all duration-200;
    }
  }

  &:not(.is-read-only) {
    &.focused,
    &:hover,
    &.touching {
      .rc-slider-track {
        @apply bg-th-brand-color;
      }

      .rc-slider-handle {
        @apply opacity-100;
      }
    }
  }

  &:not(.show-handle) {
    .rc-slider-handle {
      @apply opacity-0;
    }
  }

  .rc-slider-handle {
    @apply w-4 h-4 bg-white-900 border-none outline-none;
    margin-top: -0.37rem;
    border: none;
    outline-offset: 0;

    &:focus {
      @apply opacity-100;
    }
    &:active {
      box-shadow: none;
    }
  }
}
