.menu-item {
  &.active {
    @apply bg-th-foreground-low text-th-primary-foreground;
  }
}

.menu-item, 
.menu-item-mobile {
  .dynamic-icon {    
    > div {
      &:last-child {
        display: none;
      }
      &:first-child {
        display: block;
      }
    }
  }

  &:hover,
  &.active {
    .dynamic-icon {
      > div {
        &:last-child {
          display: block;
        }
        &:first-child {
          display: none;
        }
      }
    }
  }
}

.menu-item-mobile {
  @apply transition-colors duration-300;
  
  &:not(.active) {
    @apply text-th-foreground-high;
  }
}