.sidebar {
  .playlists-menu-toggler {
    .playlists-context-menu-sort {
      @apply opacity-0 transition-opacity duration-200 ease-linear;
    }

    &:hover {
      .playlists-context-menu-sort {
        @apply opacity-100;
      }
    }
  }

  &:hover {
    div:has(.playlists-menu-toggler) {
      .playlists-context-menu-sort {
        @apply opacity-100;
      }
    }
  }

  .droppable {
    position: relative;

    &.xl-droppable {
      &::after {
        @apply top-0 bottom-1 right-1 left-1;
      }
    }

    &:not(.xl-droppable) {
      &::after {
        @apply inset-0;
      }
    }

    &::after {
      content: '';
      @apply absolute border-p-1 border-th-brand-color block rounded-md transition-all duration-200 pointer-events-none opacity-0;
    }
  }

  .is-dragging-over {
    @apply text-th-primary-foreground;

    &::after {
      @apply opacity-100;
    }
  }
}

[data-dragging-type-rj] {
  .sidebar {
    .main-menu {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

[data-dragging-type-rj~='playlist'] {
  .sidebar {
    .library-menu,
    .toggler-library-menu {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

[data-dragging-type-rj~='mp3'] {
  .sidebar {
    .library-menu {
      .podcasts,
      .videos,
      .artists,
      .albums {
        opacity: 0.25;
        pointer-events: none;
      }
    }

    .playlists-menu {
      .rj-playlist {
        opacity: 0.25;
        pointer-events: none;
      }
    }
  }
}

[data-dragging-type-rj~='podcast'] {
  .sidebar {
    .library-menu {
      .songs,
      .liked-songs,
      .videos,
      .artists,
      .albums {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    .playlists-menu,
    .playlists-menu-toggler {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

[data-dragging-type-rj~='video'] {
  .sidebar {
    .library-menu {
      .songs,
      .liked-songs,
      .podcasts,
      .artists,
      .albums {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    .playlists-menu,
    .playlists-menu-toggler {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

[data-dragging-type-rj~='artist'] {
  .sidebar {
    .library-menu {
      .songs,
      .liked-songs,
      .podcasts,
      .videos,
      .albums {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    .playlists-menu,
    .playlists-menu-toggler {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}

[data-dragging-type-rj~='album'] {
  .sidebar {
    .library-menu {
      .songs,
      .liked-songs,
      .podcasts,
      .videos,
      .artists {
        opacity: 0.25;
        pointer-events: none;
      }
    }
    .playlists-menu,
    .playlists-menu-toggler {
      opacity: 0.25;
      pointer-events: none;
    }
  }
}
