.track-grid {
  a {
    display: block;
  }

  .background-holder {
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(10, 6, 9, 0.9) 90%);
  }

  .default-actions-wrapper {
    z-index: 1;
    opacity: 0;
  }

  &:hover,
  &.is-context-menu-open,
  &.is-now-playing-playing {
    .background-holder,
    .default-actions-wrapper {
      opacity: 1;
    }
  }

  &.artist, &.user {
    .default-actions-wrapper {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }

  &.is-container-style {
    @apply p-4 bg-th-primary-footer-background rounded-md transition-shadow duration-300 hover:bg-th-foreground-min-solid hover:shadow-xl h-full;
  }
}
