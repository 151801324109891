.resizer {
  user-select: none;
  cursor: col-resize;
  width: 0.4rem;

  &::after {
    content: '';
    position: absolute;
    left: 0.15rem;
    width: 0.1rem;
    top: 0px;
    bottom: 0px;
    background-color: transparent;
    transition: background-color 0.15s ease 0.15s, box-shadow 0.15s ease 0.15s;
  }

  &:hover,
  &.active {
    &::after {
      background-color: var(--th-highlight-accent);
      box-shadow: 0 0 0 0.1rem var(--th-highlight-accent);
    }
  }
}
