html {
  // NOT TOUCH DEVICE
  &:not(.mobile-web-player) {
    body,
    #__next {
      @apply h-full overflow-hidden;
    }

    @apply h-full overflow-hidden;
  }

  // TOUCH DEVICES
  &.mobile-web-player {
    @apply flex flex-col min-h-full h-auto;

    body {
      @apply flex flex-col flex-1;
    }

    #__next {
      @apply min-h-full flex flex-1 flex-col;

      -webkit-tap-highlight-color: transparent;
    }

    a {
      -webkit-touch-callout: none;
    }
  }
}

html,
body,
#__next {
  width: 100%;
  color: var(--primary-foreground);
  background-color: var(--primary-background);
}

.font-farsi {
  font-family: farsi, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.text-1-vmax {
  font-size: 1vmax;
}

svg {
  vertical-align: middle;
}

img {
  user-select: none;
  user-drag: none;
  -webkit-user-drag: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.ReactVirtualized__Masonry,
.ReactVirtualized__Grid {
  outline: 0 !important;
}

.native-drag {
  -webkit-user-select: none;
  -webkit-app-region: drag;
}

.disable-native-drag {
  -webkit-app-region: no-drag;
}

.cropper-container {
  @apply rounded-md overflow-hidden;
}

.cropper-hidden {
  display: none;
}
.cropper-view-box,
.cropper-face {
  border-radius: 50%;
}
.cropper-view-box {
  outline: 0;
  border: 5px solid #fff;
}
.cropper-modal {
  opacity: 0.6;
}

textarea {
  resize: none;
}

.cursor-grab {
  cursor: grab;
}

.rtl {
  direction: rtl;
}

.app-bg-gradient-2 {
  box-shadow: rgb(255 255 255 / 25%) 0px 1px 1px 0px inset;
  background: linear-gradient(155.91deg, rgb(24, 25, 28) 21.92%, rgb(33, 34, 37) 77.49%);
}