// https://cssgradient.io/
.rj-gradient-1 {
    background-image: linear-gradient(178.2deg, theme('colors.roof-terracotta.500') 10.9%, #811008 62.6% );

    &.hover-gradient {
        &:hover {
            text-shadow: rgb(0 0 0 / 56%) 0px 0.3rem 0.8rem;
            box-shadow: #811008 0px 0.1rem 2.5rem;
        }
    }
}

.rj-gradient-2 {
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  theme('colors.roof-terracotta.500') 0%, #811008 90% );

    &.hover-gradient {
        &:hover {
            text-shadow: rgb(0 0 0 / 56%) 0px 0.3rem 0.8rem;
            box-shadow: #811008 0px 0.1rem 2.5rem;
        }
    }
}

.cover-gradient {
    background-image: radial-gradient( circle farthest-corner at 10% 20%,  var(--start-gradient) 0%, var(--end-gradient) 90% );
}


.black-radial-gradient {
    --radial: 3rem;
    background-image: radial-gradient(black 0px, black var(--radial), transparent 50%);
    
    @media (min-width: 768px) {
        --radial: 4rem;
    }
}
