.grecaptcha-badge { 
  visibility: hidden;
}

.backface-hidden {
  backface-visibility: hidden;
}

.gradient-from-transparent-black-600 {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6) 99%);
}

.hidden-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar { 
    display: none;
  }
}

.touch-callout-none {
  -webkit-touch-callout: none;
}

.scroll-mask {
  &.sm-mask {
    --size: 2rem;
  }

  &.lg-mask {
    --size: 10rem;
  }

  /* autoprefixer: off */
  // -webkit-mask-image: linear-gradient(0, transparent 0, #000000 var(--size), #000000 calc(100% - var(--size)), transparent 100%);
  // mask-image: linear-gradient(0, transparent 0, #000000 var(--size), #000000 calc(100% - var(--size)), transparent 100%);
}

@media not all and (min-resolution:.001dpcm) { 
  img[loading="lazy"] { 
    clip-path: inset(0.5px);
   }
}

.safari-mask {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}

.text-shadow {
  text-shadow: rgb(0 0 0 / 56%) 0px 0.3rem 0.8rem;
}