
.shiny-background {
    @apply overflow-hidden relative;

    &::after {
        content: '';
        @apply absolute inset-0;
        background-size: 300% 100%;
        animation: background-position-r-t-l 4s infinite linear;
        background-image: linear-gradient(90deg, transparent 33%,rgba(255, 255, 255, 0.3) 50%, transparent 66%);
    }
}

.bg-animation-gradient {
    animation-name: gradient;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    animation-duration: var(--animation-duration, 15s);
    background-size: 400% 400%;
}

@media (prefers-reduced-motion: reduce) {
    .img-intro.opacity-0 {
        opacity: 1 !important;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .img-intro {
        animation: fade-in .15s ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        opacity: 0;
    }
    
    .long-slide-down {
        animation: slide-down 100s linear infinite;
        will-change: transform;
    }
    
    .long-slide-x {
        animation: var(--animation-duration, 32s) linear 0s infinite normal backwards running slide-x;
    }

    .fade-in-features-app {
        animation: 700ms ease-out 0s 1 normal backwards running fade-in;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
  
@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes background-position-r-t-l {
    0% {
      background-position: right;    
    }
    20% {
      background-position: left;
    }
    100% {
        opacity: 0;
    }
  }
  
@keyframes come-in-out {
    0% {
        transform: scale(0);
        }
    50% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes slide-down {
    0% {
        transform: translate3d(0, 0, 0);
    }

    100% {
        transform: translate3d(0, -100%, 0);
    }
}

@keyframes slide-x {
    0% {
        transform: translateX(var(--tw-translate-x, 0%));
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

