.fade {
  pointer-events: none;
  z-index: 1;
  transition: 0.5s ease opacity;
  opacity: 0;

  &.active {
    opacity: 1;
  }

  &.top {
    background: linear-gradient(var(--secondary-background), transparent 3rem);
  }

  &.bottom {
    background: linear-gradient(
      transparent calc(100% - 3rem),
      var(--secondary-background)
    );
  }
}
