.subscription-fancy-header {
  &::after,
  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    z-index: 1;
  }

  &::after {
    border-width: 11rem 0 0 8rem;
    border-color: transparent transparent transparent #79211b;
    left: 0;
  }

  &::before {
    right: 0;
    border-width: 0 0 11rem 8rem;
    border-color: transparent transparent #79211b transparent;
  }
}
