/* @font-face {
  font-family: 'inseri';
  src: url('./persian/irseri.eot');
  src: url('./persian/irseri.eot') format('embedded-opentype'),
    url('./persian/irseri.woff') format('woff'), url('./persian/irseri.ttf') format('truetype'),
    url('./persian/irseri.svg#IranianSerifRegular') format('svg');
} */

/* @font-face {
  font-family: radio-javan;
  src: url(./light.woff2) format('woff2'), url(./light.woff) format('woff'),
    url(./light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: radio-javan;
  src: url(./book.woff2) format('woff2'), url(./book.woff) format('woff'),
    url(./book.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: radio-javan;
  src: url(./bold.woff2) format('woff2'), url(./bold.woff) format('woff'),
    url(./bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: radio-javan;
  src: url(./black.woff2) format('woff2'), url(./black.woff) format('woff'),
    url(./black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: farsi;
  src: url('./farsi/Vazirmatn-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: farsi;
  src: url('./farsi/Vazirmatn-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
