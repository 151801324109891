:root,
[data-theme='radio javan'] {
  --brand-color: theme('colors.roof-terracotta.500');
  --primary-top-navbar: theme('colors.roof-terracotta.500');
  --primary-footer-background: theme('colors.mine-shaft.500');
  --primary-background: theme('colors.cod-gray.500');
  --secondary-background: theme('colors.mine-shaft.500');
  --inverted-background: theme('colors.white.900');
  --primary-foreground: theme('colors.white.900');
  --inverted-foreground: theme('colors.black.900');
  --foreground-max: theme('colors.white.700');
  --foreground-high: theme('colors.white.500');
  --foreground-mid: theme('colors.white.200');
  --foreground-low: theme('colors.white.70');
  --foreground-min: theme('colors.white.50');
  --foreground-max-solid: rgb(206, 206, 206);
  --foreground-high-solid: rgb(158, 158, 158);
  --foreground-mid-solid: rgb(88, 88, 88);
  --foreground-low-solid: rgb(47, 47, 47);
  --foreground-min-solid: rgb(37, 37, 37);
  --highlight: rgba(29, 155, 209, 1);
  --highlight-hover: rgba(29, 155, 209, 1);
  --th-highlight-accent: rgba(29, 155, 209, 1);
  --th-secondary-highlight: rgba(242, 199, 68, 1);
}

[data-theme='dark-plus'] {
  --brand-color: theme('colors.roof-terracotta.500');
  --primary-top-navbar: rgb(24, 24, 24);
  --primary-footer-background: rgb(24, 24, 24);
  --primary-background: rgb(18, 18, 18);
  --secondary-background: rgb(0, 0, 0);
  --inverted-background: theme('colors.white.900');
  --primary-foreground: theme('colors.white.900');
  --inverted-foreground: theme('colors.black.900');
  --foreground-max: theme('colors.white.700');
  --foreground-high: theme('colors.white.500');
  --foreground-mid: theme('colors.white.200');
  --foreground-low: theme('colors.white.70');
  --foreground-min: theme('colors.white.50');
  --foreground-max-solid: rgb(219, 219, 219);
  --foreground-high-solid: rgb(185, 185, 185);
  --foreground-mid-solid: rgb(53, 53, 53);
  --foreground-low-solid: rgb(27, 27, 27);
  --foreground-min-solid: rgb(24, 24, 24);
  --highlight: rgba(29, 155, 209, 1);
  --highlight-hover: rgba(29, 155, 209, 1);
  --th-highlight-accent: rgba(242, 199, 68, 1);
  --th-secondary-highlight: rgba(242, 199, 68, 1);
}
