[data-simplebar] {
  &.simplebar-dragging {
    .simplebar-content {
      @apply select-none pointer-events-none;
    }

    .simplebar-track {
      pointer-events: all;
    }
  }

  .simplebar-wrapper {
    overflow: hidden;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;

    .simplebar-height-auto-observer-wrapper {
      box-sizing: inherit !important;
      height: 100%;
      width: 100%;
      max-width: 1px;
      position: relative;
      float: left;
      max-height: 1px;
      overflow: hidden;
      z-index: -1;
      padding: 0;
      margin: 0;
      pointer-events: none;
      flex-grow: inherit;
      flex-shrink: 0;
      flex-basis: 0;

      .simplebar-height-auto-observer {
        box-sizing: inherit;
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 1000%;
        width: 1000%;
        min-height: 1px;
        min-width: 1px;
        overflow: hidden;
        pointer-events: none;
        z-index: -1;
      }
    }

    .simplebar-mask {
      direction: inherit;
      position: absolute;
      overflow: hidden;
      padding: 0;
      margin: 0;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: auto !important;
      height: auto !important;
      z-index: 0;

      .simplebar-offset {
        direction: inherit !important;
        box-sizing: inherit !important;
        resize: none !important;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0;
        margin: 0;
        -webkit-overflow-scrolling: touch;

        .simplebar-content-wrapper {
          direction: inherit;
          box-sizing: border-box !important;
          position: relative;
          display: flex;
          height: 100%; /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
          width: auto;
          max-width: 100%; /* Not required for horizontal scroll to trigger */
          max-height: 100%; /* Needed for vertical scroll to trigger */
          scrollbar-width: none;
          -ms-overflow-style: none;
          outline: none;

          /*Fix safari issue in download page*/
          > div {
            @apply flex flex-col flex-1;
          }
          
          .simplebar-content {
            &::before,
            &::after {
              content: ' ';
              display: table;
            }
          }
        }
      }
    }

    .simplebar-placeholder {
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      pointer-events: none;
    }
  }

  .simplebar-track {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: hidden;

    .simplebar-scrollbar {
      @apply absolute rounded-md left-0 right-0 min-h-6;

      &::before {
        content: '';
        left: 2px;
        right: 2px;
        transition: opacity 0.2s linear;
        @apply absolute rounded-md opacity-0 bg-th-foreground-high;
      }

      &.simplebar-visible {
        &::before {
          @apply opacity-100;
          transition: opacity 0s linear;
        }
      }
    }

    &.simplebar-vertical {
      @apply top-0;

      &.xs {
        @apply w-1.5;
      }

      &.sm {
        @apply w-2;
      }

      &.md {
        @apply w-2.5;
      }
      
      .simplebar-scrollbar {
        &::before {
          top: 2px;
          bottom: 2px;
        }
      }
    }

    &.simplebar-horizontal {
      @apply left-0;

      &.xs {
        @apply h-1.5;
      }

      &.sm {
        @apply h-2;
      }

      &.md {
        @apply h-2.5;
      }

      .simplebar-scrollbar {
        top: 2px;
        height: 7px;
        min-width: 10px;
        width: auto;
        @apply right-auto left-0 min-h-0;
        
        &:before {
          @apply h-full;
          left: 2px;
          right: 2px;
        }
      }
    }
  }
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  @apply w-0 h-0;
}

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  @apply left-0 right-auto;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
