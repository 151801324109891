.gap-0 {
  --grid-columns-gap: theme('gap.0');
}
.gap-2 {
  --grid-columns-gap: theme('gap.2');
}
.gap-4, .gap-x-4 {
  --grid-columns-gap: theme('gap.4');
}
.gap-6 {
  --grid-columns-gap: theme('gap.6');
}

.desktop-grid-carousel {
  --shadow-offset: theme('gap.4');

  @apply grid grid-flow-col items-stretch p-4 -m-4 overflow-x-auto overflow-y-hidden scroll-smooth hidden-scrollbar snap-x snap-mandatory relative;
  
  grid-auto-columns: var(--grid-max-content-xlarge, calc((100% - (var(--grid-columns-count) - 1) * var(--grid-columns-gap)) / var(--grid-columns-count)));
  grid-template-rows: repeat(var(--grid-rows-count, 1), minmax(0, 1fr));
  width: calc(100% + (var(--shadow-offset) * 2));
  mask-image: linear-gradient(90deg, transparent 0, #000 var(--shadow-offset, 1rem), #000 calc(100% - var(--shadow-offset, 1rem)), transparent 100%);


  // > div {
  //   @apply flex;

  //   > div {
  //     @apply flex-1;
  //   }
  // }
}

.desktop-grid {
  @apply grid auto-rows-auto;

  grid-template-columns: repeat(var(--grid-columns-count), minmax(0, 1fr));
}