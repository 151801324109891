.ios-loading {
  path:nth-of-type(1) {
    animation: ios-loading-pulse 1s infinite linear;
  }
  path:nth-of-type(2) {
    animation: ios-loading-pulse 1s -.083s infinite linear;
  }
  path:nth-of-type(3) {
    animation: ios-loading-pulse 1s -.166s infinite linear;
  }
  path:nth-of-type(4) {
    animation: ios-loading-pulse 1s -.249s infinite linear;
  }
  path:nth-of-type(5) {
    animation: ios-loading-pulse 1s -.332s infinite linear;
  }
  path:nth-of-type(6) {
    animation: ios-loading-pulse 1s -.415s infinite linear;
  }
  path:nth-of-type(7) {
    animation: ios-loading-pulse 1s -.498s infinite linear;
  }
  path:nth-of-type(8) {
    animation: ios-loading-pulse 1s -.581s infinite linear;
  }  
  path:nth-of-type(9) {
    animation: ios-loading-pulse 1s -.664s infinite linear;
  }
  path:nth-of-type(10) {
    animation: ios-loading-pulse 1s -.747s infinite linear;
  }
  path:nth-of-type(11) {
    animation: ios-loading-pulse 1s -.83s infinite linear;
  }
  path:nth-of-type(12) {
    animation: ios-loading-pulse 1s -.913s infinite linear;
  }
}

@keyframes ios-loading-pulse {
  50% { 
    opacity: 100%;
  }
  to { 
    opacity: 0%;
  }
}